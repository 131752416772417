import { gsap } from 'gsap/all';
import Section from './Section';

const myName = 'Home';

let instance;

class Home extends Section {
  constructor() {
    super(myName);
  }

  init(callback) {
    super.init(callback);

    const options = {
      threshold: 0.1
    };

    this.intersections = [];

    const observer = new IntersectionObserver((entries) => {
      for (let i = 0; i < entries.length; i++) {
        const entry = entries[i];

        if (entry.isIntersecting) {
          entry.target.classList.add('intersecting');
        } else {
          entry.target.classList.remove('intersecting');
        }
      }
    }, options);

    const elements = [].slice.call(this.elements.sectionWrapper.querySelectorAll('.portfolio>li'));

    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      observer.observe(element);
    }

    // const update = () => {
    //   window.requestAnimationFrame(update);
    //   for (let i = 0; i < this.intersections.length; i++) {
    //     const element = this.intersections[i];
    //     console.log(element.target);
    //   }
    // };

    // update();

    const onClick = (e) => {
      const hometop = document.querySelector('#home').offsetTop;
      console.log(e.target.id, hometop + document.querySelector('#drawings').offsetTop, hometop + document.querySelector('#development').offsetTop);
      if (e.target.id === 'drawings_link') {
        e.preventDefault();
        gsap.to(document.documentElement, { scrollTop: hometop + document.querySelector('#drawings').offsetTop, duration: 0.75, ease: 'power4.inOut' });
      }

      if (e.target.id === 'projects_link') {
        e.preventDefault();
        gsap.to(document.documentElement, { scrollTop: hometop + document.querySelector('#development').offsetTop, duration: 0.75, ease: 'power4.inOut' });
      }
    };

    document.body.querySelector('#about').addEventListener('click', onClick);
  }

  show(callback) {
    super.show(callback);
  }

  hide(callback) {
    super.hide(callback);
  }
}

export default {
  getInstance() {
    instance = instance || new Home();
    return instance;
  },
};

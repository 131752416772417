class Section {
  constructor(myName) {
    console.log('Section constructor', myName);
    this.name = myName;
  }

  init(callback) {
    console.log('Section init', this.name);

    this.initialized = true;
    this.elements = {
      sectionWrapper: document.querySelector(`#${this.name.toLowerCase()}`)
    };

    if (callback) callback();
  }

  getTitle() {
    return this.name.toUpperCase();
  }

  show(callback) {
    console.log(this, `show name ${this.name}`);

    if (callback) callback();
  }

  hide(callback) {
    console.log(`hide ${this.name}`);
    if (callback) callback();
  }

  startup(callback) {
    console.log(`startup ${this.name}`);
    if (callback) callback();
  }

  shutdown(callback) {
    console.log(`shutdown ${this.name}`);
    this.elements.sectionWrapper.classList.remove('show', 'hide');
    if (document.activeElement) document.activeElement.blur();

    if (callback) callback();
  }

  destroy() {
    console.log('DESTROY', this.name);
    this.added = false;
    this.initialized = false;

    if (this.elements.sectionWrapper.parentElement) this.elements.sectionWrapper.parentElement.removeChild(this.elements.sectionWrapper);
    if (this.elements) Object.keys(this.elements).forEach((key) => delete this.elements[key]);
    if (this.elements) delete this.elements;
  }

  resize() {
    console.log('THIS RESIZE', this.headerScene);
    if (this.headerScene) this.headerScene.resize();
  }
}

export default Section;

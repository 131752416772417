const global = {
  verbose: true,
  debug: false,
  site_path: window.site_path,
  colors: {
    black: '#1f1f1f',
    white: '#ffffff',
    orange: '#f5b331',
    dark_gray: '#1f1f1f'
  }
};

export default global;
